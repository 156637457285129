<!-- @format -->

<template>
  <div>
    <div v-if="loader" class="">
      <div
        role="status"
        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border mb-5 bg-white"
      >
        <div
          class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
        >
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="flex items-center justify-between w-full">
          <div>
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
            ></div>

            <div
              class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
            ></div>
          </div>
          <div class="pl-52 pb-3">
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-80 my-2"
            ></div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="mt-10">
          <div class="p-5 border border-gray-200 bg-white animate-pulse">
            <div class="flex flex-col justify-center items-center h-80">
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
              ></div>

              <div
                class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
              ></div>
            </div>
            <div class="flex justify-end">
              <div
                class="bg-gray-300 rounded dark:bg-gray-500 w-32 mb-2.5 px-8 py-5"
              ></div>
            </div>
          </div>
          <div class="mt-10 bg-white">
            <div
              role="status"
              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border"
              v-for="key in 2"
              :key="key"
            >
              <div
                class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
              >
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path
                    d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
                  />
                </svg>
              </div>
              <div class="flex items-center justify-between w-full">
                <div>
                  <div
                    class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
                  ></div>

                  <div
                    class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
                  ></div>
                </div>
                <div class="pl-52 pb-3">
                  <div
                    class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-32 my-2"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="py-4 px-6 flex w-full gap-4 bg-white rounded-md">
        <div class="w-16">
          <img
            class="w-full"
            src="../../../../assets/icons/compliance_File.svg"
          />
        </div>
        <div class="w-full">
          <div class="text-md font-base line-clamp capitalize">
            {{ compliance?.name }}
          </div>
          <div class="flex pt-2 gap-3 align-center">
            <div class="flex gap-2 font-light text-sm items-center capitalize">
              <span class="material-icons w-5 text-md text-teal"
                >format_list_bulleted_icon</span
              >
              {{ compliance?.sub_type }}
            </div>
            <div class="flex font-light text-sm items-center">
              <img
                class="w-6 pr-1"
                src="../../../../assets/icons/calendar_clock.svg"
              />
              {{
                compliance?.due_date
                  ? moment(compliance?.due_date).format("MMM DD, YYYY")
                  : moment(compliance?.end_date).format("MMM DD, YYYY")
              }}
            </div>
            <div class="flex gap-2 font-light text-sm items-center">
              <span class="material-icons w-5 text-teal">update_icon</span>
              {{
                compliance?.is_recurring
                  ? occurenceFormat(compliance?.occurrence)
                  : "Once"
              }}
            </div>
          </div>
        </div>
        <div
          class="w-1/4 flex items-center justify-end cursor-pointer"
          @click="
            downloadButtonClicked(
              compliance?.compliance_item_files[0]?.file,
              fileName,
              compliance?.compliance_calendar_type?.name,
              compliance?.id
            )
          "
        >
          <button class="text-teal font-medium">Download</button>
          <span class="material-icons w-5 text-teal px-1">save_alt_icon</span>
        </div>
      </div>
      <div
        class="p-8 border rounded-lg mt-8"
        :class="
          complianceStatus === 5
            ? 'bg-gray-100 cursor-not-allowed'
            : ' bg-lightTeal '
        "
      >
        Submit {{ compliance?.compliance_calendar_type?.name }}
        <div
          class="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible"
          v-if="loading"
        >
          <svg
            class="text-gray-100 animate-spin"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
          >
            <path
              d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
              stroke="currentColor"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
              stroke="currentColor"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-teal"
            ></path>
          </svg>
        </div>
        <div v-else>
          <div
            v-if="
              (submittedFile === null && acceptedFiles === null) ||
              (compliance?.compliance_calendar_type?.name
                ?.toLowerCase()
                ?.includes('policies') &&
                acceptedFiles === null)
            "
            class="flex items-center justify-center text-gray-700 border-dashed border-2 h-32 rounded-md my-8"
            :class="
              complianceStatus === 5 ? ' border-gray-400 ' : '  border-teal'
            "
            v-bind="getRootProps()"
          >
            <input
              v-bind="getInputProps()"
              :disabled="
                compliance?.compliance_calendar_type?.name.includes('Policies')
                  ? complianceStatus === 5
                    ? true
                    : false
                  : false
              "
              @change="errorUploading = false"
            />
            <div>
              Drag or upload files here or
              <span
                class="cursor-pointer"
                :class="
                  complianceStatus === 5 ? ' text-gray-500 ' : '  text-teal'
                "
                @click="populateError(complianceStatus === 5)"
                >&nbsp;Browse here</span
              >
            </div>
          </div>
          <small class="text-red capitalize" v-if="!isReviewed"
            >Kindly review policy before uploading</small
          >
          <div
            class="p-4 flex w-full items-center gap-4 backgroundClass rounded-md my-8"
            v-if="
              (!compliance?.compliance_calendar_type?.name
                ?.toLowerCase()
                ?.includes('policies') &&
                submittedFile !== null) ||
              acceptedFiles !== null
            "
          >
            <div class="w-12">
              <img
                class="w-full"
                src="../../../../assets/icons/compliance_File.svg"
              />
            </div>
            <div
              class="w-full"
              v-for="item in compliance?.compliance_calendar_type?.name
                ?.toLowerCase()
                ?.includes('policies')
                ? acceptedFiles
                : submittedFile
                ? submittedFile
                : acceptedFiles"
              :key="item.name"
            >
              <div class="text-md line-clamp-2 capitalize">
                {{ acceptedFiles ? item.name : item.file_name }}
              </div>
              <div class="flex gap-3 align-center">
                <div
                  class="flex gap-2 font-light text-sm items-center capitalize"
                >
                  {{
                    item?.created_at
                      ? moment(item?.created_at).format("MMM DD, YYYY")
                      : moment(date).format("MMM DD, YYYY")
                  }}
                </div>
              </div>
            </div>
            <div
              class="w-1/4 flex items-center justify-end"
              v-if="
                (!compliance?.compliance_calendar_type?.name
                  ?.toLowerCase()
                  ?.includes('policies') &&
                  submittedFile === null) ||
                acceptedFiles !== null
              "
            >
              <span
                class="material-icons w-5 text-red px-1 cursor-pointer"
                @click="removeFile()"
                >close</span
              >
            </div>
          </div>
          <small
            v-if="errorUploading && !isFileUploaded.flag"
            class="text-red capitalize"
            >Kindly Upload File to submit!</small
          >
          <small v-if="!isFileUploaded.flag" class="text-red">{{
            isFileUploaded.msg
          }}</small>
        </div>
        <div class="grid justify-items-end">
          <div
            v-if="
              submittedFile === null ||
              compliance?.compliance_calendar_type?.name
                ?.toLowerCase()
                ?.includes('policies')
            "
          >
            <button
              class="px-10 py-3 text-white rounded-lg font-light"
              :class="
                acceptedFiles && !clickedButton
                  ? 'bg-teal border border-teal'
                  : 'bg-gray-300 border border-gray-300 cursor-not-allowed'
              "
              @click.prevent="submitForm"
              :disabled="clickedButton"
            >
              Submit
            </button>
          </div>

          <div
            v-if="
              !compliance?.compliance_calendar_type?.name
                ?.toLowerCase()
                ?.includes('policies') && submittedFile !== null
            "
            class="backgroundClass px-12 py-4 rounded-lg text-teal text-lg"
          >
            Submitted
          </div>
        </div>
      </div>
      <div v-show="submittedFile !== null">
        <div
          class="p-8 bg-white border rounded-lg mt-8"
          v-if="
            compliance?.compliance_calendar_type?.name
              ?.toLowerCase()
              ?.includes('policies')
          "
        >
          <div class="text-lg">List Of Policies</div>
          <div v-if="submittedFile !== null || acceptedFiles !== null">
            <div
              class="w-full flex justify-between"
              v-for="(item, key) in submittedFile"
              :key="key"
            >
              <div
                class="py-4 flex justify-between w-full items-center gap-4 border border-gray px-6 rounded-md my-4"
                :class="
                  key === 0 && isRecent ? ' bg-teal-light bg-opacity-10' : ''
                "
              >
                <div class="">
                  <div class="flex gap-2">
                    <div class="w-10">
                      <img
                        class="w-full"
                        src="../../../../assets/icons/compliance_File.svg"
                      />
                    </div>

                    <div class="flex-col">
                      <div class="text-md line-clamp-2 capitalize">
                        {{ item.file_name }}
                      </div>
                    </div>
                  </div>
                  <div class="px-10 pt-1">
                    <div
                      class="flex gap-2 font-light text-sm items-center capitalize"
                    >
                      <span class="material-icons w-5 text-teal"
                        >event_available_outlined</span
                      >
                      {{
                        item?.created_at
                          ? moment(item?.created_at).format("MMM DD, YYYY")
                          : moment(date).format("MMM DD, YYYY")
                      }}
                      <span class="material-icons w-5 text-teal"
                        >history_toggle_off_oulined</span
                      >

                      {{
                        moment(
                          item?.created_at?.split("T")[0] +
                            "T" +
                            item?.created_at?.split("T")[1]?.split(".")[0]
                        ).format("hh:mm A")
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="w-1/4 flex items-center justify-end cursor-pointer"
                  @click="downloadFile(item?.file, item?.file_name)"
                >
                  <button class="text-teal font-medium">Download</button>
                  <span class="material-icons w-5 text-teal px-1"
                    >save_alt_icon</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useDropzone } from "vue3-dropzone";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
import useDownloadFile from "@/composables/useDownloadFile";

const { getRootProps, getInputProps, ...rest } = useDropzone({
  onDrop,
  maxFiles: 1,
  maxSize: 2000000,
  accept: [".pdf", ".docx"],
});
const restVlaue = ref(null);
const loader = ref(false);
const acceptedFiles = ref(null);
const isFileUploaded = ref({ flag: true, msg: "" });
const { downloadFile } = useDownloadFile();
const store = useStore();
const clickedButton = ref(false);
const loading = ref(false);
const route = useRoute();
const errorUploading = ref(false);
const uploadedFile = ref(false);
const complianceId = route.params.complianceId;
const date = new Date();
const isReviewed = ref(true);
const isRecent = ref(false);

function onDrop(acceptFiles, rejectReasons) {
  acceptedFiles.value = acceptFiles;
  isFileUploaded.value.flag = true;
  isFileUploaded.value.msg = "";
  if (rejectReasons) {
    rejectReasons.map((item) => {
      acceptedFiles.value = null;
      if (item.errors[0].message.includes("Too many files")) {
        isFileUploaded.value.flag = false;
        isFileUploaded.value.msg = "Please select one file at a time!";
      }
      if (
        item.errors[0].message.includes("File is larger than 2000000 bytes")
      ) {
        isFileUploaded.value.flag = false;
        isFileUploaded.value.msg = "File Size Must Be Smaller Than 2MB";
      }
    });
  }
  restVlaue.value = rest;
}

const populateError = (flag) => {
  if (flag) {
    isReviewed.value = false;
  } else {
    isReviewed.value = true;
  }
};

const occurenceFormat = (occurrence) => {
  switch (occurrence) {
    case 1:
      return "One Month";
    case 2:
      return "Two Months";
    case 3:
      return "Three Months";
    case 4:
      return "Four Months";
    case 5:
      return "Five Months";
    case 6:
      return "Six Months";
    case 7:
      return "Seven Months";
    case 8:
      return "Eight Months";
    case 9:
      return "Nine Months";
    case 10:
      return "Ten Months";
    case 11:
      return "Eleven Months";
    case 12:
      return "Twelve Months";
  }
};

const removeFile = () => {
  errorUploading.value = false;
  acceptedFiles.value = null;
};

const submittedFile = computed(() => {
  if (compliance.value?.submitted_compliance_item_files?.length > 0) {
    return compliance.value?.submitted_compliance_item_files;
  } else {
    return null;
  }
});

const downloadButtonClicked = (
  fileToBeDownloaded,
  fileName,
  complianceType,
  complianceId
) => {
  downloadFile(fileToBeDownloaded, fileName);

  if (complianceType?.toLowerCase()?.includes("policies")) {
    store
      .dispatch("maComplianceCalender/policyReviewd", {
        practiceId: localStorage.getItem("practice"),
        complianceId: complianceId,
      })
      .then(() => {
        isReviewed.value = true;
      });
  }
};

const submitForm = () => {
  const formData = new FormData();
  if (acceptedFiles.value && acceptedFiles.value.length > 0) {
    acceptedFiles.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    clickedButton.value = true;
    loading.value = true;
    formData.append("practice", localStorage.getItem("practice"));
    formData.append("compliance_item", complianceId);
    store
      .dispatch("maComplianceCalender/submitCompliance", formData)
      .then(() => {
        loading.value = false;
        uploadedFile.value = true;
        loading.value = false;
        errorUploading.value = false;
        clickedButton.value = false;
        isRecent.value = true;
        acceptedFiles.value = null;
        Swal.fire({
          showConfirmButton: false,
          padding: "40px",
          timer: 3000,
          width: "40%",
          background: `rgba(225, 249, 240, 1)`,
          timerProgressBar: false,
          iconHtml: `<img src="${icon}" />`,
          customClass: {
            title: "custom-title",
            popup: "border-radius",
            content: "custom-text",
            icon: "icon-border",
          },
          title: "Your File has been submitted successfully",
        });
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        loading.value = false;
        isFileUploaded.value.flag = false;
        isFileUploaded.value.msg = "Error Uploading File kindly try again!";
        clickedButton.value = false;
      });
  } else {
    errorUploading.value = true;
    clickedButton.value = false;
  }
};

const compliance = computed(() => {
  return store.getters["maComplianceCalender/getSingleCompliance"];
});

const fileName = computed(() => {
  if (compliance.value?.compliance_item_files?.length > 0) {
    const url = compliance.value?.compliance_item_files[0]?.file;
    const fileName = url.split("/").pop();
    return decodeURIComponent(fileName);
  }
  return null;
});
const complianceStatus = computed(() => {
  if (compliance.value?.practices?.length > 0) {
    return compliance.value?.practices[0]?.status;
  }
  return null;
});

const fetchSingleCompliance = async () => {
  loader.value = true;
  await store
    .dispatch("maComplianceCalender/fetchSingleCompliances", {
      complianceId: complianceId,
      practiceId: localStorage.getItem("practice"),
    })
    .then(() => {
      loader.value = false;
    });
};

onMounted(fetchSingleCompliance);
</script>

<style scoped>
.backgroundClass {
  background-color: #00a4991a;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
